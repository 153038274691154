import React from 'react';
import { Container } from 'ui/containers';
import Layout from 'components/Layout';
import { useTranslation } from 'react-i18next';

const HotelReview = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Container>{t('templates.review')}</Container>
    </Layout>
  );
};

export default HotelReview;
